import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CargarScriptsService } from 'src/app/cargar-scripts.service';
import { MessageService } from 'src/app/services/message.service';
import { NgForm } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';

import { HttpClient } from '@angular/common/http';
 

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  name: string;
  email: string;
  message: string;

  constructor(
    private title: Title,
    private _CargarScripts: CargarScriptsService,
    public _MessageService: MessageService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.title.setTitle('Edgard Moya Godoy | Contact');
  }

  /*submitForm() {
    const mensaje= "Hi! Someone writes you from your page. \n\nMy name is " + this.name + ". My email is " + this.email +". \nMy message is \n\n" + this.message + ".";
    
    this._MessageService.sendMessage(mensaje).subscribe(() => {
      swal("Formulario de contacto", "Mensaje enviado correctamente", 'success');
      });
    */
  /*contactForm(form) {
        this._MessageService.sendMessage(form).subscribe(() => {
          swal("Formulario de contacto", "Mensaje enviado correctamente", 'success');
        });

  } */

  onSubmit(contactForm: NgForm) {
    if (contactForm.valid) {
      const email = contactForm.value;
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/f/xyylrple',
        { name: email.name, replyto: email.email, message: email.messages },
        { 'headers': headers }).subscribe(
          response => {
            
          }
        );
    }
  }
}
