<div class="background-header"></div>
<app-navbaresp></app-navbaresp>
<div class="background-img"></div>
<div class="hero is-fullheight">
  <div class="row">
    <div class="formulario">
      <h1 class="title">Contáctame</h1>
      <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
        <div class="form-group">
          <label class="label">Nombre</label>
          <input class="stef" type="text" placeholder="Name" name="name" ngModel required #name="ngModel">
        </div>
        <div class="form-group">
          <label class="label">Email</label>
          <input class="stef" type="text" placeholder="email@example.com" email name="email" ngModel required #email="ngModel">
        </div>
        <div class="form-group">
          <label class="label">Mensaje</label>
          <textarea cols="30" rows="6" class="stef" placeholder="Messages" name="messages" ngModel required
            #messages="ngModel"></textarea>
        </div>


        <input class="btn btn-dark" type="submit" value="Enviar">
      </form>

    </div>

  </div>

</div>



