<div class="background-img"></div>
<header class="background-header"><app-navbar></app-navbar></header>
<section class="background-instrumental">
    <div class="container">
        <div  class="row">
            <h2>"I have a deep spiritual need to compose."</h2>
            <div id="cuadro" class="col-lg-7 col-sm-12">
                <iframe id="youtube1" width="500" height="281" src="https://www.youtube.com/embed/rdnpJwtvYzU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
            <div id="cuadro"   class="col-lg-5 col-sm-12">
                <br>
                <h1 id="Verbo">Verbo Luz</h1>
                <p class="text1"> 
                    Choir of the Singing Seminar of the Theater Libertador San Martín
                    <br>Conductor: Mº Matías Saccone
                    <br>
                    <br>Basilica Nuestra Señora de la Merced
                    <br>Córdoba - Argentina
                    <br>Wednesday 30 May 2018</p>
            </div>
        </div>
        <div id="pc"class="row">
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <br>
                <h1 id="Desenlace">Desenlace</h1>
                <p class="text">
                    <br>
                    Cordoba Municipal Choir
                    <br>
                    Premiere Concert
                    <br>New Choral Works
                    <br>Conductor: Tomás Arinci
                    <br>Guest Conductor: Edgard Moya Godoy
                    <br>
                    <br>Córdoba - 2016</p>
            </div>
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <iframe id="youtube2" width="500" height="281" src="https://www.youtube.com/embed/19upSwv5HCU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
        </div>
        <div id="mobile"class="row">

            <div id="cuadro" class="col-lg-6 col-sm-12">
                <iframe id="youtube3" width="500" height="281" src="https://www.youtube.com/embed/19upSwv5HCU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
            
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <h1 id="Desenlace">Desenlace</h1>
                <p class="text" > <br>
                    Cordoba Municipal Choir
                    <br>
                    Premiere Concert
                    <br>New Choral Works
                    <br>Conductor: Tomás Arinci
                    <br>Guest Conductor: Edgard Moya Godoy
                    <br>
                    <br>Córdoba - 2016</p>
                
                
            </div>
        </div>
    </div>
    <div class="container">

        <div class="row py-2 mt-5">
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center  ">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/506cc4sA9JQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Kyrie Eleison</h2>
            </div>
    
        </div>
        <div class="row py-2 mt-5">
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Descenlace (scoreview)</h2>
            </div>
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center justify-content-end">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/9hauMe2GaXQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            
    
        </div>
        <div class="row py-2 mt-5">
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center  ">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/XETpOPbGKcI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Salmo 46</h2>
            </div>
            
    
        </div>

        <div class="row py-2 mt-5">
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Contemplaciones I</h2>
            </div>
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center justify-content-end">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/Y0WOUf5EcqE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            
    
        </div>
        <div class="row py-2 mt-5">
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/NetGf80l6o4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Copla Libre</h2>
            </div>
    
        </div>
        <div class="row py-2 mt-5">
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Copla Libre(scoreview)</h2>
            </div>
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center justify-content-end">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/ZUZkf6PfNAY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            
    
        </div>
    </div>
</section>
