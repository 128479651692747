<header class="background-header"><app-navbaresp></app-navbaresp></header>
<div class="background-img"></div>
<section class="background-instrumental">
    <div class="container">
        <div  class="row">
            <h2>"Buscar y encontrar las maneras de acercarme al intérprete a través de mis ideas es una aventura, un desafío, que me entusiasma inmensamente".</h2>
            <div id="cuadro" class="col-lg-7 col-sm-12">
                <iframe id="youtube1" width="500" height="281" src="https://www.youtube.com/embed/P8x_xWcXDho" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
            <div id="cuadro"   class="col-lg-5 col-sm-12">
                <br>
                <h1 id="nocturno">NoCtUrNo</h1>
                <p class="text1"> 
                    
                    Dúo Joyas - Chavesta
                    <br>Diego Joyas (saxo soprano)
                    <br>Emilio Chavesta (piano)
                    <br>
                    <br>La Hora Acusmática 
                    <br>Viernes 14 de Octubre de 2016
                    <br>Cepia - Fac. de Artes - UNC</p>
            </div>
        </div>
        <div id="pc"class="row">
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <h1>Sonatina para Violin y Percursión</h1>
                <p class="text">
                    <br>
                    Interpretes:
                    Vanessa Fuentes Maniaci (Violín)
                    Mariano Marzari (percusión)
                    
                    <br>Esta es una obra compuesta según el plan tripartito de la sonata clásica, 
                    con dos complejos temáticos diferenciados por sus características: 
                    el primero de índole melódico-armónico; el segundo de carácter rítmico.
                    Los materiales se desenvuelven en instancias de naturaleza tímbrica, 
                    en la complementariedad e interacción textural, en el desarrollo y elaboración 
                    de la energía de cada uno de ellos, en una construcción que es en si misma, desde su origen, gestual.</p>
            </div>
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <iframe id="youtube2" width="500" height="281" src="https://www.youtube.com/embed/Y_kbLpk2b7Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
        </div>
        <div id="mobile"class="row">

            <div id="cuadro" class="col-lg-6 col-sm-12">
                <iframe id="youtube3" width="500" height="281" src="https://www.youtube.com/embed/Y_kbLpk2b7Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <h1>Sonatina para Violin y Percursión</h1>
                <p class="text"> Interpretes:
                    <br>Vanessa Fuentes Maniaci (Violín)
                    <br>Mariano Marzari (percusión)
                    <br>
                    <br>Esta es una obra compuesta según el plan tripartito de la sonata clásica, 
                    con dos complejos temáticos diferenciados por sus características: 
                    el primero de índole melódico-armónico; el segundo de carácter rítmico.
                    Los materiales se desenvuelven en instancias de naturaleza tímbrica, 
                    en la complementariedad e interacción textural, en el desarrollo y elaboración 
                    de la energía de cada uno de ellos, en una construcción que es en si misma, desde su origen, gestual.</p>
                
                
            </div>
        </div>
    </div>

</section>
