import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorksService {
  obras = [
    {
      id: 1,
      type: 'choral',
      min: 12,
      text: 'Edgard Moya Godoy',
      name: 'Bienaventuranza',
      time: '03:00 min',
      year: 2006,
      purchase: 'https://goldberg-verlag.com/en/contact/',
      english: {
        clasification: 'Vidala(Argentine Folklore)',
        theme: 'Sacred',
        language: 'Spanish',
        description:
          'It is traversed by flavors of the vidala* from the North of Argentina, which accompany the strong nature of the melody with a resonant and percussive texture. Simple duet passages anticipate the counterpoint leading to the exultant worship climax. It is based on Apocalypse 1:3 and it is the first work I composed for choir.',
      },
      spanish: {
        clasification: 'Vidala(Folclore Argentino)',
        theme: 'Sacra',
        language: 'Español',
        description:
          'Aromas vidaleros del norte argentino la atraviesan y acompañan el carácter firme de la melodía en una una textura resonante y percusiva. Sencillos pasajes a dúo anticipan el contrapunto que conduce al clímax exultante de alabanza. Basada en Apocalipsis 1:3, es la primera obra que compuse para coro.',
      },
      formation: 'SATB (c/div)',
      difficulty: 2,
      image: 'assets/img/obras/Catalogo/BLANCO/bienaventuranzaa.png',
      price: 0,
      preview: 'https://goldberg-verlag.com/en/contact/',
    },
    {
      id: 2,
      type: 'choral',
      min: 12,
      text: 'Traditional',
      name: 'Kyrie Eleison',
      time: '03:00 min',
      year: 2008,
      purchase: 'https://goldberg-verlag.com/en/contact/',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'Greek',
        description:
          'This work is characterized by its double imitative counterpoint, the use of upper pedals and ostinati, densely colorful harmonies, as well as the exploration of timbre in some phonemes. The resonance is placed in the poetics of reverence, humility, and solemnity. It is a deep and delicate prayer, but it is, above all, human.',
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Griego',
        description:
          'Esta obra está caracterizada por el contrapunto imitativo en dúos, el uso de pedales superiores y ostinati, armonías densamente coloridas y la exploración tímbrica de algunos fonemas. La resonancia es la poética de lo reverente, humilde y solemne. Es una oración profunda y delicada, pero sobre todo, humana.',
      },
      formation: 'SATB (c/div)',
      difficulty: 3,
      image: 'assets/img/obras/Catalogo/BLANCO/kyrie_eleison.png',
      price: 0,
      preview: 'https://goldberg-verlag.com/en/contact/',
    },
    {
      id: 3,
      type: 'choral',
      min: 12,
      text: 'Traditional',
      name: 'Stabat Mater',
      time: '02:30 min',
      year: 2008,
      purchase: 'cart',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'Latin',
        description:
          'Rising pain takes form in the journey that goes from the unison to the chords’ opening. Within the homophony, sharp sonorities bring forward my personal interpretation of the text and its expressiveness. Its result is a small but greatly eloquent piece.',
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Latin',
        description:
          'El dolor en aumento se figura en el trayecto que va desde el unísono a la apertura de los acordes. Dentro de la homofonía, sonoridades punzantes ponen en relieve mi lectura personal del texto y su expresividad. El resultado es una pieza pequeña pero de gran elocuencia.',
      },
      formation: 'SATB',
      difficulty: 3,
      image: 'assets/img/obras/Catalogo/BLANCO/STABAT_MATER.png',
      price: 2.35,
      preview: 'https://www.calameo.com/read/0070472822b2cb243ced1',
    },
    {
      id: 4,
      type: 'choral',
      min: 12,
      text: 'Traditional',
      name: 'Gloria',
      time: '02:30 min',
      year: 2008,
      purchase: 'cart',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'Spanish',
        description:
          'This bright piece displays several ways to express worship. The harmony is the prism that refracts the light of this praise, embellishing the simple word ‘glory’ with grand chords and an exultant counterpoint. It is brief but nonetheless contains great strength and impact.',
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Español',
        description:
          'Pieza de carácter brillante que expone diversas maneras de expresar adoración. La armonía es el prisma que atraviesa la luz de esta alabanza, coloreando la simple palabra ‘gloria’ con grandes acordes y un contrapunto exultante. Una pieza breve pero de gran fuerza e impacto.',
      },
      formation: 'SATB (c/div a 8)',
      difficulty: 4,
      image: 'assets/img/obras/Catalogo/BLANCO/gloriaa.png',
      price: 2.35,
      preview: 'https://www.calameo.com/read/00704728234b53108bd71',
    },
    {
      id: 5,
      type: 'choral',
      min: 12,
      text: 'Edgard Moya Godoy',
      name: 'Linde Espiral',
      time: '04:00 min',
      year: 2010,
      purchase: 'cart',
      english: {
        clasification: 'Choral Song',
        theme: 'Love',
        language: 'Spanish',
        description:
          'Clear allusions to the medieval madrigal, albeit done in a contemporary context and with an almost descriptive treatment of the text, supply this work with a distinctive sound quality. While its course presents a great challenge to the choir, it brings about an innovative aesthetic result that resignifies the song form.',
      },
      spanish: {
        clasification: 'Cancion Coral',
        theme: 'Secular',
        language: 'Español',
        description:
          'Claras alusiones al madrigal medieval pero en un contexto actual, con un tratamiento del texto cuasi descriptivo, dotan a esta obra de una cualidad sonora particular. Su discurrir presenta un gran desafío para el coro pero con un resultado estético novedoso que resignifica la forma canción.',
      },
      formation: 'SSAATTBB',
      difficulty: 4,
      image: 'assets/img/obras/Catalogo/BLANCO/linde_espirall.png',
      price: 3.7,
      preview: 'https://www.calameo.com/read/0070472828948d29c55cc',
    },
    {
      id: 6,
      type: 'choral',
      min: 12,
      text: 'Edgard Moya Godoy',
      name: 'Contemplaciones I',
      time: '10:00 min',
      year: 2010,
      purchase: 'cart',
      english: {
        clasification: 'Choral Song',
        theme: 'Abstract',
        language: 'Spanish',
        description:
          'This work, composed for a mixed voice choir, is a conceptual triptych. Such a concept originates from the progressive re-signification of the material related to the text’s expressiveness in three movements. In this path, the musical-poetic relation is guided by the construction of layers, in a counterpoint compulsion where unit and essence come from the motive gesture and the interval construction, as well as the voice’s expressive pursuit as a conception of timbre from the phoneme and diverse types of emission.',
      },
      spanish: {
        clasification: 'Obra libre en 3 momentos',
        theme: 'Secular',
        language: 'Español',
        description:
          'Esta obra compuesta para coro mixto es un tríptico conceptual. Este concepto discurre a partir de la resignificación progresiva de los materiales en relación a la expresividad del texto en sus tres movimientos. En esta trayectoria la relación poética-musical se rige básicamente por la construcción en capas, en una coacción contrapuntística donde la unidad y la esencia deviene de la construcción interválica y del gesto motívico, como así también desde la búsqueda expresiva de la voz como concepción tímbrica a partir del fonema o diversos tipos de emisión.',
      },
      formation: 'SATB (c/div)',
      difficulty: 5,
      image: 'assets/img/obras/Catalogo/BLANCO/contemplaciones_I.png',
      price: 6.5,
      preview: 'https://www.calameo.com/read/007047282b62f36f6b0a9',
    },
    {
      id: 7,
      type: 'choral',
      min: 12,
      text: 'Bible (Psalm 46)',
      name: 'Salmo 46',
      time: '07:00 min',
      year: 2012,
      purchase: 'https://goldberg-verlag.com/en/contact/',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'Latin',
        description:
          "This work composed for a women's choir is a personal and poetic exploration of the acoustic space as a metaphor for the omnipresence of the divine attributes. This poetic permeates all the sound organizations based on a re-signification of the madrigals from the Middle Ages. The counterpoint, body percussion, harmonic and melodic configurations are all in a close relationship with the explicit intention of “painting” the text with sound.",
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Latin',
        description:
          'Esta obra compuesta para coro femenino es una exploración personal y poética del espacio acústico como metáfora de la omnipresencia de los atributos divinos.Esta poética atraviesa todas las organizaciones sonoras a partir de una resignificación de los madrigalismos de la Edad Media. El contrapunto, la percusión corporal, las configuraciones armónicas y melódicas, todo está en estrecha relación con una intención explícita de “pintar” el texto con sonidos.',
      },
      formation: 'SATB (c/div a 8)',
      difficulty: 4,
      image: 'assets/img/obras/Catalogo/BLANCO/salmos_46.png',
      price: 0,
      preview: 'https://goldberg-verlag.com/en/contact/',
    },
    {
      id: 8,
      type: 'choral',
      min: 12,
      text: 'Traditional, based on',
      name: 'O bone Jesu',
      time: '04:00 min',
      year: 2013,
      purchase: 'cart',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'Latin',
        description:
          'The ancient principles of addition and overlap, Middle Age tropes, are the origin of this work. The traditional text thereby becomes a conversational prayer. An archaic vice is combined with a harmonic and counterpoint treatment alluding to a personal vision of the new tonal practices from the beginning of the 20th Century.',
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Latin',
        description:
          'Los principios antiguos de adhesión y superposición, los tropos de la Edad Media, dan origen a esta obra. El texto tradicional se convierte así en una oración contestada. Un resabio arcaico se conjuga con el tratamiento armónico y contrapuntístico que aluden a una visión personal de las prácticas neotonales de inicios del SXX.',
      },
      formation: 'SATB (c/div a 8)',
      difficulty: 4,
      image: 'assets/img/obras/Catalogo/BLANCO/o_bone_jesus.png',
      price: 3.0,
      preview: 'https://www.calameo.com/read/007047282c52b60f2a941',
    },
    {
      id: 9,
      type: 'choral',
      min: 12,
      text: 'Sebastián Palacios',
      name: 'Desenlace',
      time: '04:00 min',
      year: 2014,
      purchase: 'https://goldberg-verlag.com/en/contact/',
      english: {
        clasification: 'Tango',
        theme: 'Secular',
        language: 'Spanish',
        description:
          'What shapes this piece is circularity as a concept and my playful approach to tango. Denouement is a delicate work that celebrates life, love, and luminosity, expressing the complexities of living through its diverse textural designs and the certainty of the highest ideals through the firmness of its typically Argentine rhythmic impulses.',
      },
      spanish: {
        clasification: 'Tango',
        theme: 'Secular',
        language: 'Español',
        description:
          'Lo circular como concepto y mi aproximación lúdica al tango moldean esta pieza. Desenlace es una obra delicada que celebra la vida, el amor y lo luminoso, expresando las complejidades del vivir en los diversos diseños texturales y la certeza de los ideales más elevados en la firmeza de sus impulsos rítmicos propiamente argentinos.',
      },
      formation: 'SATB',
      difficulty: 4,
      image: 'assets/img/obras/Catalogo/BLANCO/desenlace.png',
      price: 0,
      preview: 'https://goldberg-verlag.com/en/contact/',
    },
    {
      id: 10,
      type: 'choral',
      min: 12,
      text: 'Based on Song of Solomon Chapter 1 & 2',
      name: 'Canticle I – the Rose, the Lily',
      time: '05:00 min',
      year: 2014,
      purchase: 'cart',
      english: {
        clasification: 'Free work',
        theme: 'Secular',
        language: 'English',
        description:
          'It is a dialog between two lovers who bring out each other’s beauty and virtues. The mirror structures and the layout of the auditory space capture this idea of reflection and complementarity. Such is part of my poetry, expressing how love bonds men and women based on mutual appreciation.',
      },
      spanish: {
        clasification: 'Obra libre',
        theme: 'Secular',
        language: 'Ingles',
        description:
          'Es el diálogo entre dos enamorados que resaltan mutuamente su belleza y virtudes. Las estructuras en espejo y la disposición del espacio de audición plasman esta idea de reflejo y complementariedad. Esto forma parte de mi poética, expresando cómo el amor vincula al hombre y a la mujer a partir de la valoración mutua.',
      },
      formation: 'SATB',
      difficulty: 5,
      image: 'assets/img/obras/Catalogo/BLANCO/canticle_ir.png',
      price: 3.7,
      preview: 'https://www.calameo.com/read/007047282b7e651821e87',
    },
    {
      id: 11,
      type: 'choral',
      min: 12,
      text: 'Traditional',
      name: 'Letanía I - Gloria',
      time: '07:00 min',
      year: 2014,
      purchase: 'cart',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'Spanish',
        description:
          'It is a nearly minimalistic praise where the entire vocal register is made up of harmony as a metaphor of faith intersecting everyday life. Multiple musical resources express poetically different facets of the human being in this act of worship. Although it is a demanding composition, it offers an exceedingly novel and satisfying aesthetic result.',
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Español',
        description:
          'Es una alabanza cuasi minimalista donde todo el registro vocal es ocupado  por la armonía como una metáfora de la fe atravesando lo cotidiano. Diversos recursos musicales expresan poéticamente distintas facetas del ser humano en actitud de adoración. Una obra desafiante pero de un resultado estético en extremo novedoso y satisfactorio.',
      },
      formation: 'SATB',
      difficulty: 5,
      image: 'assets/img/obras/Catalogo/BLANCO/letania_I.png',
      price: 3.7,
      preview: 'https://www.calameo.com/read/007047282b7e651821e87',
    },
    {
      id: 12,
      type: 'choral',
      min: 12,
      text: 'Based on',
      name: 'Dominus Deus',
      time: '04:00 min',
      year: 2015,
      purchase: 'https://goldberg-verlag.com/en/contact/',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'Latin-Spanish',
        description:
          "“The Lord is among you.” - it expresses the experience of omnipresence; God occupying everything. The rhythmic quality is complemented by the sweetness of the lyrical aspect. Through the poetics of the listening space, the audience becomes engulfed in the mighty experience of divine power and omnipresence, combined with the expression of the deity's endless esteem for us.",
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Latin-Español',
        description:
          '“El Señor está en medio tuyo”; la experiencia de lo omnipresente; Dios ocupándolo todo. El carácter rítmico se complementa con la dulzura de lo lírico. A través de la poética del espacio de audición, el oyente se sumerge en la potente experiencia de la omnipresencia y el poder divino, combinados con la expresión de estima infinita de la deidad por nosotros.',
      },
      formation: 'SATB',
      difficulty: 5,
      image: 'assets/img/obras/Catalogo/BLANCO/dominus_deus.png',
      price: 0,
      preview: 'https://goldberg-verlag.com/en/contact/',
    },
    {
      id: 13,
      type: 'choral',
      min: 12,
      text: 'Based on Psalm 56',
      name: 'In God I have put my trust',
      time: '04:00 min',
      year: 2016,
      purchase: 'cart',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'English',
        description:
          "This piece evolves from an austere and almost lyrical quality, a metaphor for fear, to a rhythmic and vehement nature that stands as a representation of confidence. The contrast between the peace constantly sought by humankind and the fear provoked by those everyday 'enemies' results in a vigorous work of great dramatic tension.",
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Ingles',
        description:
          'Esta pieza avanza desde lo austero y cuasi lírico, metáfora del temor, a lo rítmico y vehemente como figura de lo seguro. El contraste entre la paz que el ser humano busca y el miedo que despiertan aquellos ‘enemigos’ cotidianos dan por resultado una obra enérgica de gran carga dramática.',
      },
      formation: 'SATB',
      difficulty: 4,
      image:
        'assets/img/obras/Catalogo/BLANCO/In_God_I_have_put_my_trust.png',
      price: 3.0,
      preview: 'https://www.calameo.com/read/0070472822653d8fea923',
    },
    {
      id: 14,
      type: 'choral',
      min: 12,
      text: 'Based on John’s Gospel',
      name: 'Verbo Luz',
      time: '06:00 min',  
      year: 2018,
      purchase: 'https://goldberg-verlag.com/en/contact/',
      english: {
        clasification: 'Motete',
        theme: 'Sacred',
        language: 'Spanish',
        description:
          'This work for a mixed voice choir is based on the first five verses from John’s Gospel. The musical proposal departs from the idea of austerity. This quasi-minimalistic idea already presents a sound feature from its interval, with a short-lived melodic gesture of only three notes that gives rise to the entire course of the work. My poetic intention is to represent the unity of the trinity, the omnipresence, the creative power, the grandiosity, the luminance and the glorious, all of them, attributes of God.',
      },
      spanish: {
        clasification: 'Motete',
        theme: 'Sacra',
        language: 'Español',
        description:
          'Esta obra para coro mixto está basada en los primeros cinco versículos del Evangelio según San Juan. La propuesta musical parte de la idea de austeridad. Esta idea cuasi minimalista ya presenta una característica sonora a partir de su interválica, con un gesto melódico de corto aliento de apenas tres notas que da origen a todo el desenvolvimiento de la obra. Mi intención poética es la de representar la unidad de lo trinitario, la omnipresencia, el poder creador, la grandiosidad, lo lumínico, lo glorioso; todos ellos, atributos de Dios.',
      },
      formation: 'SATB',
      difficulty: 4,
      image: 'assets/img/obras/Catalogo/BLANCO/VerboLuz.png',
      price: 0,
      preview: 'https://goldberg-verlag.com/en/contact/',
    },
    {
      id: 15,
      type: 'choral',
      min: 12,
      text: 'Edgard Moya Godoy',
      name: 'Nos ha nacido Emmanuel',
      time: '03:00 min',
      year: 2018,
      purchase: 'https://goldberg-verlag.com/en/contact/',
      english: {
        clasification: 'Carol',
        theme: 'Christmas',
        language: 'Spanish',
        description:
          'With a Christmas theme and a lively and joyful nature, this work is composed with rhythmic features alluding to Argentine folklore. Its text links the foretold promise of a Messiah and its fulfillment with the nativity of Christ by means of a simple narration that takes us to the same night of the birth in Bethlehem.',
      },
      spanish: {
        clasification: 'Villancico',
        theme: 'Navidad',
        language: 'Español',
        description:
          'Con temática navideña, de carácter vivo y alegre, compuesta con rasgos rítmicos alusivos al folklore argentino. Su texto relaciona la promesa profética del Mesías y su cumplimiento en la natividad de Jesucristo a través de una sencilla narración que nos sitúa en la noche misma del nacimiento en Belén.',
      },
      formation: 'SATB',
      difficulty: 2,
      image: 'assets/img/obras/Catalogo/BLANCO/Nos_ha_nacido_Emmanuel.png',
      price: 0,
      preview: 'https://goldberg-verlag.com/en/contact/',
    },
    {
      id: 16,
      type: 'choral',
      min: 1,
      text: 'Bible (John’s Gospel)',
      name: 'Cánticos del Evangelio según San Juan ',
      time: '1 hour 15 min',
      year: 2018,
      purchase: 'cart',
      english: {
        clasification: 'Cicle (sacred) – 22 numbers',
        theme: 'Sacred',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Ciclo (sacro) – 22 números',
        theme: 'Sacra',
        language: 'Español',
      },
      formation: 'SATB',
      difficulty: 3,
      image:
        'assets/img/obras/Catalogo/BLANCO/cantos_sj.png',
      price: 21.0,
      preview: '/#/contact',
    },
    {
      id: 17,
      type: 'choral',
      min: 12,
      text: 'Edgard Moya Godoy',
      name: 'Zamba Vacía',
      time: '04:00 min',
      year: 2019,
      purchase: 'cart',
      english: {
        clasification: 'Zamba (Argentine Folklore)',
        theme: 'Folklore',
        language: 'Spanish',
        description:
          'Conceived in all its aspects on the basis of the Argentine folklore genre which is ideal to manifest the most delicate and sublime of feelings, nostalgia, and pain — the zamba. This text is the raw expression of loneliness and abandonment. Empty Zamba combines lyrics and rhythm resulting in a melancholic piece of vast expressiveness.',
      },
      spanish: {
        clasification: 'Zamba',
        theme: 'Folklore',
        language: 'Español',
        description:
          'Concebida en todos sus aspectos a partir del género folklórico argentino ideal para manifestar los sentimientos más delicados y sublimes, las nostalgias y los dolores: la zamba. El texto es la expresión cruda de la soledad y el abandono. Zamba Vacía conjuga lo lírico con lo rítmico dando por resultado una obra melancólica de inmensa expresividad.',
      },
      formation: 'SATB',
      difficulty: 3,
      image: 'assets/img/obras/Catalogo/BLANCO/zamba_vacia.png',
      price: 3.0,
      preview: '/#/contact',
    },
    {
      id: 18,
      type: 'choral',
      min: 12,
      text: 'Edgard Moya Godoy',
      name: 'Copla Libre',
      time: '04:00 min',
      year: 2020,
      purchase: 'cart',
      english: {
        clasification: 'Vidala (Argentine Folklore)',
        theme: 'Fraternity',
        language: 'Spanish',
        description:
          'Composed at the onset of the COVID-19 pandemic, this modest piece reflects on the human “being”. With gestures inherent to Argentine folklore, a text conveying feelings and circumstances that allude to equality is set to music. Through this common singing voice, a luminous message of hope emerges, more valid now than ever before.',
      },
      spanish: {
        clasification: 'Vidala',
        theme: 'Fraternidad',
        language: 'Español',
        description:
          'Compuesta al inicio de la pandemia por  COVID-19, esta sencilla pieza reflexiona sobre el “ser” humano. Con gestos propios del folklore argentino se musicaliza un texto que expresa sentimientos y circunstancias que aluden a la igualdad. A través de esta voz común que canta se eleva un mensaje luminoso de esperanza, vigente hoy más que nunca.',
      },
      formation: 'SATB',
      difficulty: 3,
      image: 'assets/img/obras/Catalogo/BLANCO/Copla_Libre.png',
      price: 2.5,
      preview: 'https://www.calameo.com/read/0070472826415bd969609',
    },
    {
      id: 19,
      type: 'choral',
      min: 1,
      text: 'Bible (John’s Gospel)',
      name: 'Cánticos del Evangelio según San Juan (parte 2)',
      time: '50:00 min',
      year: 2021,
      purchase: 'cart',
      english: {
        clasification: 'Cicle (sacred) – 16 numbers',
        theme: 'Sacred',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Ciclo (sacro) – 16 números',
        theme: 'Sacra',
        language: 'Español',
      },
      formation: 'SATB',
      difficulty: 3,
      image:
        'assets/img/obras/Catalogo/BLANCO/cantos_sj_2.png',
      price: 17.3,
      preview: '/#/contact',
    },
    {
      id: 20,
      type: 'chamber',
      min: 3,
      text: '-',
      name: 'Pequeño canon percusivo',
      time: '01:30 min',
      year: 2008,
      purchase: 'cart',
      english: {
        clasification: 'Canon',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Canon',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: '3 percussion',
      difficulty: 0,
      image:
        '',
      price: 2.1,
      preview: '/#/contact',
    },
    {
      id: 21,
      type: 'chamber',
      min: 3,
      text: '-',
      name: 'Bewilderment I',
      time: '03:10 min',
      year: 2008,
      purchase: 'cart',
      english: {
        clasification: 'Canon',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Canon',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Piano solo',
      difficulty: 0,
      image:
        '',
      price: 3.2,
      preview: '/#/contact',
    },
   
    {
      id: 22,
      type: 'chamber',
      min: 1,
      text: '-',
      name: 'Leteo I',
      time: '04:00 min',
      year: 2009,
      purchase: 'cart',
      english: {
        clasification: '-',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: '-',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Piano solo',
      difficulty: 0,
      image:
        '',
      price: 3.8,
      preview: '/#/contact',
    },
    {
      id: 23,
      type: 'chamber',
      min: 2,
      text: '-',
      name: 'De barro',
      time: '03:30 min',
      year: 2009,
      purchase: 'cart',
      english: {
        clasification: 'Tango',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Tango',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Piano + flauta',
      difficulty: 0,
      image:
        '',
      price: 3.8,
      preview: '/#/contact',
    },
    {
      id: 24,
      type: 'chamber',
      min: 1,
      text: '-',
      name: 'Algunos fragmentos de rocío',
      time: '02:20 min',
      year: 2009,
      purchase: 'cart',
      english: {
        clasification: '-',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: '-',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Piano solo',
      difficulty: 0,
      image:
        '',
      price: 3.2,
      preview: '/#/contact',
    },
    {
      id: 25,
      type: 'chamber',
      min: 1,
      text: '-',
      name: 'The Greenhorn and The Hearing',
      time: '01:15 min',
      year: 2009,
      purchase: 'cart',
      english: {
        clasification: 'Lied',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Lied',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Piano soprano',
      difficulty: 0,
      image:
        '',
      price: 4.1,
      preview: '/#/contact',
    },
    {
      id: 26,
      type: 'chamber',
      min: 2,
      text: '-',
      name: 'Dos piezas para piano',
      time: '01:15 min',
      year: 2013,
      purchase: 'cart',
      english: {
        clasification: 'Lied',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Lied',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Piano solo',
      difficulty: 0,
      image:
        '',
      price: 3.2,
      preview: '/#/contact',
    },
    {
      id: 27,
      type: 'chamber',
      min: 2,
      text: '-',
      name: 'NoCtUrNo',
      time: '11:00 min',
      year: 2015,
      purchase: 'cart',
      english: {
        clasification: 'Piece in five movements',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Pieza en 5 movimientos',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Saxo soprano + Piano',
      difficulty: 0,
      image:
        '',
      price: 5.65,
      preview: '/#/contact',
    },
    {
      id: 28,
      type: 'chamber',
      min: 2,
      text: '-',
      name: 'Ictúsica - Sonatina para Violín y Percusión',
      time: '10:00 min',
      year: 2015,
      purchase: 'cart',
      english: {
        clasification: 'Sonata',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Sonata',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Saxo soprano + Piano',
      difficulty: 0,
      image:
        '',
      price: 5.65,
      preview: '/#/contact',
    },
    {
      id: 29,
      type: 'chamber',
      min: 3,
      text: '-',
      name: 'El Carancanfunfa',
      time: '06:30 min',
      year: 2016,
      purchase: 'cart',
      english: {
        clasification: 'Contemporary Tango',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Tango Contemporaneo',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Two violins + Piano',
      difficulty: 0,
      image:
        '',
      price: 5.10,
      preview: '/#/contact',
    },
    {
      id: 30,
      type: 'chamber',
      min: 3,
      text: '-',
      name: 'Oración',
      time: '06:30 min',
      year: 2017,
      purchase: 'cart',
      english: {
        clasification: '-',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: '-',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Soprano + Violín + Piano',
      difficulty: 0,
      image:
        '',
      price: 4.10,
      preview: '/#/contact',
    },
    {
      id: 31,
      type: 'chamber',
      min: 3,
      text: '-',
      name: '-pausa-',
      time: '04:20 min',
      year: 2018,
      purchase: 'cart',
      english: {
        clasification: '-',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: '-',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Soprano + Violín + Piano',
      difficulty: 0,
      image:
        '',
      price: 4.10,
      preview: '/#/contact',
    },
    {
      id: 32,
      type: 'chamber',
      min: 2,
      text: '-',
      name: 'D (for dances)',
      time: '07:00 min',
      year: 2022,
      purchase: 'cart',
      english: {
        clasification: 'Sonata',
        theme: 'Instrumental',
        language: 'Spanish',
      },
      spanish: {
        clasification: 'Sonata',
        theme: 'Instrumental',
        language: 'Español',
      },
      formation: 'Clarinete + violin',
      difficulty: 0,
      image:
        '',
      price: 4.10,
      preview: '/#/contact',
    },
  ];

  constructor() {}
  obtenerWork() {
    return this.obras;
  }
  obtenerUno(i) {
    return this.obras.find((item) => item.id == i);
  }

  obtenerObservableWork() {
    return of(this.obras);
  }
}
