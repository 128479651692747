import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filterName'
})



export class FilterNamePipe implements PipeTransform {

  transform(value: any[], text: string, option: string, difficulty: number): any[] {
    if (difficulty == 0){
    if(option == 'chamber') return value.filter(work => work.type==option && work.name.toUpperCase().includes(text.toUpperCase())) 
    if(option == 'Sacred') return value.filter(work => work.english.theme==option && work.name.toUpperCase().includes(text.toUpperCase())) 
    if(option == 'All') return value.filter(work => work.name.toUpperCase().includes(text.toUpperCase()) )
    if(option == 'Secular') return value.filter(work => work.english.theme!='Sacred' && work.name.toUpperCase().includes(text.toUpperCase())) 
    if(difficulty) return value.filter(work => work.difficulty == difficulty && work.name.toUpperCase().includes(text.toUpperCase()))
    if(!text) return value;    
    return value.filter(work => work.name.toUpperCase().includes(text.toUpperCase()))}
    else{
      if(option == 'Sacred') return value.filter(work => work.english.theme==option && work.name.toUpperCase().includes(text.toUpperCase()) && work.difficulty == difficulty) 
      if(option == 'All') return value.filter(work => work.name.toUpperCase().includes(text.toUpperCase()) && work.difficulty == difficulty)
      if(option == 'Secular') return value.filter(work => work.english.theme!='Sacred' && work.name.toUpperCase().includes(text.toUpperCase()) && work.difficulty == difficulty) 
      if(difficulty) return value.filter(work => work.difficulty == difficulty && work.name.toUpperCase().includes(text.toUpperCase()))
      if(!text) return value;    
      return value.filter(work => work.name.toUpperCase().includes(text.toUpperCase()))
    }
  }
  
}
