import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quantityFilter'
})
export class QuantityFilterPipe implements PipeTransform {

  transform(value: unknown): any {
    if (value < 16 ){
      return 12
    }
    else{
      return 1
    }
  }

}
