<div class="cortina" *ngIf="display else nothing">
    
<div class="sidebar">
    <div class="close">
        <img (click)="close(display)" src="./../../../assets/svg/close.svg" alt="">
    </div>
    <div class="container">
        <div class="row">
            <div class="cart-container">
                <h1>My cart</h1>

            </div>
            
            <div class="container" id="item-container">
                <div class="menu-container">
                    <div class="menu-space-container"></div>
                    <div class="menu">
                        <div>Price</div>
                        <div>Units</div>
                        <div class="precio-total">
                            <div>Total</div>
                            <div>Price</div>
                        </div>
                    </div> 
                </div>
                <div class="mt-5"></div>
                <div class="items" *ngFor = "let element of cart;index as i">
                    <div class="img-container">
                        
                        <img *ngIf="element.image != '';else noimage" [src]="element.image" alt="">
                        <ng-template #noimage>
                            <div class="noimage text-center py-4">
                                <p>{{element.name}}</p>
                            </div>
                        </ng-template>
                    </div>
                    <div class="cart-options">
                        <p>us{{element.price | currency: 'USD'}}</p>
                    </div>
                    <div class="quantity">                        
                        
                        <div class="quantityvalue">
                            <img (click)="restQuantity(i)"  src="./../../../assets/svg/dash.svg" alt="">
                            <p>{{element.quantity}}</p>
                            <img (click)="addQuantity(i)" src="./../../../assets/svg/plus.svg" alt="">
                        </div>
                        
                    </div>
                    <div class="thrash">
                        <p>{{element.price | totalValueFilter : element.quantity}}</p>
                        <img  (click)="deleteCartElement(element.id)" src="./../../../assets/svg/thrash.svg" alt="">
                    </div>
                    
                    
                </div>
                
            </div>
            <div class="price-container">
                <div class="total-price">
                
                    <h1>Total: us{{totalPrice | currency : 'USD'}}</h1>
                </div>
            </div>
            
            
            <div class="buttons">
                
                <button *ngIf="disabledBuy" class="btn" (click)="paypal()">Buy Now</button>
                <div id="myPaypalButtons"></div>
                
                
            </div>
            
            
        </div>
    </div>
    
</div>
</div>
<ng-template #nothing>
    
</ng-template>
