<footer class="footer  bg-dark">
    <div class="contenedor">
        
        <div class="center">
            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                <div class="btn-group me-2" role="group" aria-label="First group">
                    <a target="_blank" href="https://www.facebook.com/EdgardMoyaGodoyCompositor/">
                        <button type="button" class="btn btn-dark socialmedia">
                            <img src="./../assets/svg/facebook.svg" alt="">
                        </button>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/edgard_m_godoy/">
                        <button type="button" class="btn btn-dark socialmedia">
                            <img src="./../assets/svg/instagram.svg" alt="">
                        </button>
                    </a>
                    <a target="_blank" href="https://www.youtube.com/c/edgardmoyagodoymusic">
                        <button type="button" class="btn btn-dark socialmedia">
                            <img src="./../assets/svg/youtube.svg" alt="">
                        </button>
                    </a>
                    <a target="_blank" href="https://open.spotify.com/artist/0eOP0x1nzN8DbaIYPtFvZl">
                        <button type="button" class="btn btn-dark socialmedia">
                            <img src="./../assets/svg/twitter.svg" alt="">
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <div class="center"><p class="text-align-center">Ⓒ Copyright Edgard Moya Godoy</p></div>
        <div class="center"><p class="stefano">-Developed by <a class="text-decoration-underline stefano" href="mailto:stefanoracke@gmail.com" >Stefano Räcke</a>-</p></div>
        
    </div>
   
</footer>