<div class="background-img"></div>
<header class="background-header"><app-navbar></app-navbar></header>
<section class="background-instrumental">
    <div class="container">
        <div  class="row">
            <h2>"Finding ways to connect with the interpreter through my ideas is a journey I enjoy immensely."</h2>
            <div id="cuadro" class="col-lg-7 col-sm-12">
                <iframe id="youtube1" width="500" height="281" src="https://www.youtube.com/embed/P8x_xWcXDho" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
            <div id="cuadro"   class="col-lg-5 col-sm-12">
                <br>
                <h1 id="nocturno">NoCtUrNo</h1>
                <p class="text1"> 
                    
                    Joyas - Chavesta Duo
                    <br>Diego Joyas (soprano saxophone)
                    <br>Emilio Chavesta (piano)
                    <br>
                    <br>The Acousmatic Hour
                    <br>Friday 14th October 2016
                    <br>Cepia - Fac. of Arts - National University of Córdoba</p>
            </div>
        </div>
        <div id="pc"class="row">
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <h1>Sonatina para Violin y Percursión</h1>
                <p class="text">
                    <br>
                    Performers:
Vanessa Fuentes Maniaci (Violin)
Mariano Marzari (percussion)
<br>
This is a work composed according to the tripartite plan of the classical sonata, with two thematic complexes differentiated by their characteristics: the first of a melodic-harmonic nature; the second of a rhythmic character. The materials unfold in instances of a timbral nature, in the complementarity and textural interaction, in the development and elaboration of the energy of each of them, in a construction that is in itself, from its origin, gestural.</p>
            </div>
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <iframe id="youtube2" width="500" height="281" src="https://www.youtube.com/embed/Y_kbLpk2b7Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
        </div>
        <div id="mobile"class="row">

            <div id="cuadro" class="col-lg-6 col-sm-12">
                <iframe id="youtube3" width="500" height="281" src="https://www.youtube.com/embed/Y_kbLpk2b7Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <h1>Sonatina para Violin y Percursión</h1>
                <p class="text"> <br>
                    Performers:
Vanessa Fuentes Maniaci (Violin)
Mariano Marzari (percussion)
<br>
This is a work composed according to the tripartite plan of the classical sonata, with two thematic complexes differentiated by their characteristics: the first of a melodic-harmonic nature; the second of a rhythmic character. The materials unfold in instances of a timbral nature, in the complementarity and textural interaction, in the development and elaboration of the energy of each of them, in a construction that is in itself, from its origin, gestural.</p>
                
                
            </div>
        </div>
    </div>

    <div class="container">

        <div class="row py-2 mt-5">
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/Agt8Vwvfq38" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Little Cannon</h2>
            </div>
    
        </div>
        <div class="row py-2 mt-5">
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Many ways to ask for silence</h2>
            </div>
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center  justify-content-end">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/pyLo4NiwEtE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
    
        </div>
        <div class="row py-2 mt-5">
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center ">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/U98wC86Y8Tc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>El caracanfunfa</h2>
            </div>
            
    
        </div>

        <div class="row py-2 mt-5">
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Leteo I</h2>
            </div>
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center  justify-content-end">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/MdLIYBzHHek" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            
    
        </div>

        <div class="row">
            <h2>

                Instrumental music (symphonic / ensemble / large ensemble)
            </h2>
        </div>
        <div class="row py-2 mt-5">
            <div class="col-lg-6 col-sm-12 cuadro d-flex align-items-center">
                <iframe  width="500" height="281" src="https://www.youtube.com/embed/8yzyiy6Mv1w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="col-lg-6 justify-content-center align-items-center  d-flex ">
                <h2>Rima de la Mudez y los Gritos</h2>
            </div>
    
        </div>
        
    </div>

</section>
