<header><app-navbaresp></app-navbaresp></header>
<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="6000">
        
        <div class="carousel-caption ">
          <h5>" Componer, para mí, es una necesidad profunda, espiritual."</h5>
          <p>CONCEBIRNOS EN SONIDOS</p>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="7000">
        
        <div class="carousel-caption">
          <h5>" Buscar y encontrar las maneras de acercarme al intérprete a través de mis ideas es una aventura, un desafío, que me entusiasma inmensamente."</h5>
          <p>CONCEBIRNOS EN SONIDOS</p>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="6000">
        
        <div class="carousel-caption ">
          <h5>" La plenitud de llegar a la gente que escucha mi música completa esta cinta de moebius."</h5>
          <p>CONCEBIRNOS EN SONIDOS</p>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="7000">
        
        <div class="carousel-caption ">
          <h5>" Es ahí, en ese hecho, que todo cobra sentido, porque de alguna manera, todos somos escuchados, entendidos y abrazados."</h5>
          <p>CONCEBIRNOS EN SONIDOS</p>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
    <a class="transparent"  routerLink="/es" routerLinkActive="active">Español </a>  
    <a class="transparent" href="/"> English</a>
  </div>
