import { Component, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CargarScriptsService } from 'src/app/cargar-scripts.service';
import { ObrasI } from 'src/app/core/models/obras.interface';
import { WorksService } from 'src/app/works.service';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})
export class CatalogoComponent implements  OnChanges {


  numberCart: number = 0;
  works:any[] = [];
  forTheme: FormGroup;
  element:any;
  cart: any[] = [];
  constructor( private _CargaScripts:CargarScriptsService,
    private _work:WorksService, private router:Router
    ) 
  {
    _CargaScripts.Carga(["works"]);
    _CargaScripts.Carga(["navbar"]);
    this.works = _work.obtenerWork();
    this.cart = this.getCart()
    this.numberCart = this.cart.length
   }

   filterPost = "";
   complexity = 0;
   filterTheme = ""

   themeSelector(value:string){
     this.filterTheme = value
   }

   complexitySelector(number){
    this.complexity = number
   }

  addToCart(number){
    
    this.element = this.works.find(element => element.id == number)
    this.cart = this.getCart()
    
    this.cart.push(
      {
        name:this.element.name,
        image:this.element.image,
        price:this.element.price,
        id:this.element.id,
        quantity:this.element.min,
        quantity_min:this.element.min
      }
      )
    
    this.saveCart(this.cart)
    this.numberCart = this.cart.length
  }

  transformQuantity(value){
    if (value < 16 ){
      return 12
    }
    else{
      return 1
    }
  }
  
  redirectToSingleWork(id){
    let ruta = "workcomponent/" + id;
    
    this.router.navigate(['workcomponent', id])
  }

  disableButton(number){
    this.cart = this.getCart()
    return this.cart.find(element => element.id == number) == undefined
  }

  saveCart(cart){
    localStorage.setItem("cart", JSON.stringify(cart));
    
  };

  getCart(){
    return localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  }

  ngOnChanges(changes: SimpleChanges)  {
    
    changes.cart
    
  }

}
