<div class="background-img"></div>
<header class="header">
    <app-navbaresp></app-navbaresp>
</header>
<div class="body-background">
    <div class="center-container justify-content-center align-items-center">
        <div class="justify-content-center row">
            <div class="img-container col-md-6 col-sm-12 mt-2">
                <div><img [src]=work.image></div>
            </div>
            <div class="description-container col-md-6 col-sm-12 mt-2">
                <h2>Texto por: {{work.text}}</h2>
                <h2>Duración: {{work.time}}</h2>
                <h2>Año: {{work.year}}</h2>
                <h2>Temática: {{work.spanish.theme}}</h2>
                <h2>Idioma: {{work.spanish.language}}</h2>
                <h2>Clasificación: {{work.spanish.clasification}}</h2>
                <h2>Dificultad: {{work.difficulty}} de 5</h2>
                <h2>Vista previa: <a [href]=work.preview target=”_blank”>Click para ver vista previa</a></h2>
            </div>
        </div>
        <h2 class="description text-center">{{work.english.description}}</h2>
    </div>
    <div class="btn-catalogue">
        <a href="/#/es/catalogo"><button><img src="../../../assets/svg/arrow.svg">De vuelta al catálogo</button></a>
    </div>
</div>