import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorksService } from 'src/app/works.service';

@Component({
  selector: 'app-works-components',
  templateUrl: './works-components.component.html',
  styleUrls: ['./works-components.component.css']
})
export class WorksComponentsComponent implements OnInit {
  
  work:any = [];
  
  constructor(
    private ruta:ActivatedRoute,
    private _service:WorksService
  ) {
    this.ruta.params.subscribe(params=>{
      
      this.work = this._service.obtenerUno(params['id'])
    })
   }

  ngOnInit(): void {
  }

}
