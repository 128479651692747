import { Component, OnChanges, OnInit, Output, SimpleChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CargarScriptsService } from 'src/app/cargar-scripts.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnChanges, OnInit {

  constructor( private _CargaScripts:CargarScriptsService,
    private route:ActivatedRoute
    ) 
  {
    _CargaScripts.Carga(["navbar"]);
    this.cart = this.getCart()
    this.number = this.cart.length

    
   }
  
  @Input() number = 0;
  cart: any;
  display = false;
  public activePage = location.href;
  

  public pages = [
    {
      name:'Biography',
      path: '#/biography'
    },
    {
      name: 'Catalogue',
      path: '#/works'
    },
    {
      name:'Contact',
      path: '#/contact'
    }
  ]
  
  changeValue(boolean){
    this.display = boolean
  }
  
  changeNumber($event){
    this.number = $event;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cart = this.getCart()
    this.number = this.cart.length
    changes.cart
    changes.number
    
  }

  ngOnInit(): void {
    
  }

  getCart(){
    return localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  }

  abrirCart(){
    this.display = !this.display;
    this.cart = this.getCart();
    this.number = this.cart.length
    
  }
}
