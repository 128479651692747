import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalValueFilter'
})
export class TotalValueFilterPipe implements PipeTransform {

  transform(value: number, quantity:number): any {
    return (value*quantity).toFixed(2)
  }

}
