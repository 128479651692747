<div class="background-img"></div>
<header class="header">
    <app-navbar></app-navbar>
</header>

<div class="body-background">
    <div class="center-container justify-content-center">
        <div class="justify-content-center row">
            <div class="img-container col-md-6 col-sm-12 mt-2">
                <div class="d-flex justify-content-center px-3">
                    <img *ngIf="work.image != '';else noimage" [src]=work.image>
                    <ng-template #noimage>
                        <div class="noimage text-center">
                            {{work.name}}
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class=" col-md-6 col-sm-12 mt-2">
                <div class="description-container">

                    <h2>Text by: {{work.text}}</h2>
                    <h2>Time: {{work.time}}</h2>
                    <h2>Year: {{work.year}}</h2>
                    <h2>Theme: {{work.english.theme}}</h2>
                    <h2>Language: {{work.english.language}}</h2>
                    <h2>Formation: {{work.formation}}</h2>
                    <h2>Clasification: {{work.english.clasification}}</h2>
                    <h2 *ngIf="work.difficulty!=0">Complexity: {{work.difficulty}} of 5</h2>
                </div>
                <div class="d-flex justify-content-center">

                    <a [href]=work.preview target=”_blank” class="_preview">Click to preview</a>
                </div>
            </div>
        </div>


        <h2 class="description text-center">{{work.english.description}}</h2>


    </div>
    <div class="btn-catalogue">
        <a href="/#/works"><button><img src="../../../assets/svg/arrow.svg">Back to catalogue</button></a>
    </div>
</div>