import { Component, OnInit } from '@angular/core';

let apiLoaded = false;

@Component({
  selector: 'app-choral',
  templateUrl: './choral.component.html',
  styleUrls: ['./choral.component.css']
})
export class ChoralComponent implements OnInit {

  constructor() { }

  

  ngOnInit(): void {
    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  }

  videos=[
    {
      title1:"Kyrie Eleison - 2008",
      iframe1:'506cc4sA9JQ',
      title2:"Verbo Luz - 2018 (score view)",
      iframe2:'jdLS-9Ph5uQ',
    },
    {
      title1:"",
      iframe1:"",
      title2:"",
      iframe2:"",
    },
    {
      title1:"",
      iframe1:"",
      title2:"",
      iframe2:"",
    },
    {
      title1:"",
      iframe1:"",
      title2:"",
      iframe2:"",
    },
  ]

}
