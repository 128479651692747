import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

//Servicios
import { CargarScriptsService } from './cargar-scripts.service';
import { WorksService } from './works.service';
import { MessageService } from './services/message.service';
//fin Servicios

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BiographyComponent } from './views/public/english/biography/biography.component';
import { MediaComponent } from './views/public/english/media/media.component';
import { WorksComponent } from './views/public/english/works/works.component';
import { ContactComponent } from './views/public/english/contact/contact.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './views/public/english/home/home.component';
import { ChoralComponent } from './views/public/english/choral/choral.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WorksComponentsComponent } from './components/works-components/works-components.component';
import { EsComponent } from './views/public/español/es/es.component';
import { CatalogoComponent } from './views/public/español/catalogo/catalogo.component';
import { CoralComponent } from './views/public/español/coral/coral.component';
import { InstrumentalComponent } from './views/public/español/instrumental/instrumental.component';
import { ContactoComponent } from './views/public/español/contacto/contacto.component';
import { BiografiaComponent } from './views/public/español/biografia/biografia.component';
import { NavbarespComponent } from './components/navbaresp/navbaresp.component';
import { FilterNamePipe } from './core/pipes/filter-name.pipe';
import { CartComponent } from './components/cart/cart.component';
import { QuantityFilterPipe } from './core/pipes/cart/quantity-filter.pipe';
import { TotalValueFilterPipe } from './core/pipes/cart/total-value-filter.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { ObrainformacionComponent } from './components/obrainformacion/obrainformacion.component';
import { ComissionsComponent } from './views/public/english/comissions/comissions.component';
import { PedidosComponent } from './views/public/español/pedidos/pedidos.component';


//Modules

@NgModule({
  declarations: [
    AppComponent,
    BiographyComponent,
    MediaComponent,
    WorksComponent,
    ContactComponent,
    NavbarComponent,
    HomeComponent,
    ChoralComponent,
    WorksComponentsComponent,
    EsComponent,
    CatalogoComponent,
    CoralComponent,
    InstrumentalComponent,
    ContactoComponent,
    BiografiaComponent,
    NavbarespComponent,
    FilterNamePipe,
    CartComponent,
    QuantityFilterPipe,
    TotalValueFilterPipe,
    FooterComponent,
    ObrainformacionComponent,
    ComissionsComponent,
    PedidosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

  ],
  providers: [
    CargarScriptsService,
    WorksService,
    MessageService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
