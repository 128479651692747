<script src="node_modules/jquery/dist/jquery.js"></script>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 navbar  ">
      <a class="logo  text-white " href="/">
        Edgard Moya Godoy
        <p>Composer</p>
      </a>

      <a class="cart-mobile">
        <div class="cart-number"><p class="number1">{{number}}</p></div>
        <img (click)="abrirCart()" src="../../../assets/svg/cart.svg" alt="">
      </a>
      <!--Navbar mobile-->
      <div class="collapse" id="navbarToggleExternalContent">
        <div class=" p-4">
          <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3" aria-labelledby="navbar-page">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownMenuLink" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  Media
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><a class="dropdown-item" routerLink="/choralmusic" routerLinkActive="active">Choral</a></li>
                  <li><a class="dropdown-item" routerLink="/media" routerLinkActive="active">Instrumental</a></li>

                </ul>
              </li>
              <li class="nav-item" *ngFor="let item of pages">
                <a [class.active]="item.path == activePage " class="nav-link text-white " aria-current="page" [href] = item.path
                  >{{item.name}}</a>
              </li>
              
              <li id="separator" class="nav-item language">
                <a class="nav-link text-white" routerLink="/es" routerLinkActive="active">Español</a>
              </li>
              <li class="nav-item language">
                <a class="nav-link text-white" routerLink="/" routerLinkActive="active">English</a>
              </li>

            </ul>

          </div>
        </div>
      </div>
      <nav class="navbar navbar-dark ">
        <div class="container-fluid">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <span>
              <img src="./../../../assets/svg/menu.svg" alt="">
            </span>
          </button>
        </div>
      </nav>
      <!--Finish navbar mobile-->
      <ul class="nav" id="navbar-page">
        <li class="nav-item " *ngFor="let item of pages">
          
          <a [class.active]="item.path == activePage " class="nav-link active" aria-current="page" [href] = item.path
            >{{item.name}}</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            Media
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a id="coral" class="dropdown-item" routerLink="/choralmusic" routerLinkActive="active">Choral</a></li>
            <li><a id="instrumental" class="dropdown-item" routerLink="/media"
                routerLinkActive="active">Instrumental</a></li>

          </ul>
        </li>
        <a class="cart">
          <div class="cart-number"><p class="number2">{{number}}</p></div>
          <img (click)="abrirCart()" src="../../../assets/svg/cart.svg" alt="">
        </a>
      </ul>
      
        
      
    </div>
  </div>

</div>
<app-cart [display] = display (newBooleanEvent)="changeValue($event)" [cart]= cart></app-cart>