<header class="background-header"><app-navbaresp></app-navbaresp></header>
<div class="background-img"></div>
<section class="background-instrumental">
    <div class="container">
        <div  class="row">
            <h2>"Componer, para mí, es una necesidad profunda, espiritual".</h2>
            <div id="cuadro" class="col-lg-7 col-sm-12">
                <iframe id="youtube1" width="500" height="281" src="https://www.youtube.com/embed/rdnpJwtvYzU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
            <div id="cuadro"   class="col-lg-5 col-sm-12">
                <br>
                <h1 id="Verbo">Verbo Luz</h1>
                <p class="text1"> 
                    Coro del Seminario de Canto del Teatro Libertador San Martín
                    <br>Dir.: Mº Matías Saccone
                    <br>
                    <br>Basílica Nuestra Señora de la Merced
                    <br>Córdoba - Argentina
                    <br>Miércoles 30 de Mayo de 2018</p>
            </div>
        </div>
        <div id="pc"class="row">
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <br>
                <h1 id="Desenlace">Desenlace</h1>
                <p class="text">
                    <br>
                    Coro Municipal de Córdoba
                    <br>
                    <br>Concierto de estrenos
                    <br>Nuevas Obras Corales
                    <br>Director: Tomás Arinci
                    <br>Dir. Invitado: Edgard Moya Godoy
                    <br>
                    <br>Córdoba - 2016</p>
            </div>
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <iframe id="youtube2" width="500" height="281" src="https://www.youtube.com/embed/19upSwv5HCU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
        </div>
        <div id="mobile"class="row">

            <div id="cuadro" class="col-lg-6 col-sm-12">
                <iframe id="youtube3" width="500" height="281" src="https://www.youtube.com/embed/19upSwv5HCU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
            </div>
            
            <div id="cuadro" class="col-lg-6 col-sm-12">
                <h1 id="Desenlace">Desenlace</h1>
                <p class="text"> <br>
                    Coro Municipal de Córdoba
                    <br>
                    <br>Concierto de estrenos
                    <br>Nuevas Obras Corales
                    <br>Director: Tomás Arinci
                    <br>Dir. Invitado: Edgard Moya Godoy
                    <br>
                    <br>Córdoba - 2016</p>
                
                
            </div>
        </div>
    </div>

</section>
