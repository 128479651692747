import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NgForm } from '@angular/forms';
import { HttpHeaders,  } from '@angular/common/http';

import { HttpClient } from '@angular/common/http';
 

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  name: string;
  email: string;
  message: string;

  

  constructor (private title: Title,
    
    private http: HttpClient,
    ) {
     
    }

  ngOnInit() {
    this.title.setTitle('Edgard Moya Godoy | Contact');
  }

  /*submitForm() {
    const mensaje= "Hi! Someone writes you from your page. \n\nMy name is " + this.name + ". My email is " + this.email +". \nMy message is \n\n" + this.message + ".";
    
    this._MessageService.sendMessage(mensaje).subscribe(() => {
      swal("Formulario de contacto", "Mensaje enviado correctamente", 'success');
      });
    */  
      /*contactForm(form) {
        this._MessageService.sendMessage(form).subscribe(() => {
          swal("Formulario de contacto", "Mensaje enviado correctamente", 'success');
        });

  } */

  onSubmit(contactForm: NgForm) {
    if (contactForm.valid) {
      const email = contactForm.value;
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/f/xyylrple',
        { name: email.name, replyto: email.email, message: email.messages },
        { 'headers': headers }).subscribe(
          response => {
            console.log(response);
          }
        );
    }
  }

}