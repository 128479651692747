import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { element } from 'protractor';
import { render } from 'creditcardpayments/creditCardPayments'
import { HttpHeaders } from '@angular/common/http';

import { HttpClient } from '@angular/common/http';
// import swal from 'sweetalert';
import { ObrasI } from 'src/app/core/models/obras.interface';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnChanges, OnInit {

  constructor(private http:HttpClient) { 
    
  }
  disabledBuy=true;
  totalPrice= 0;
  
  numberCart = this.getCart().length

  @Input() display: boolean;
  @Output() newBooleanEvent = new EventEmitter<boolean>();
  @Input() cart:any;
  @Output() newNumberEvent = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges) {
    changes.display
    changes.cart
    changes.totalPrice
    this.totalValue()
    
  }

  restQuantity(n){
    if(this.cart[n].quantity > this.cart[n].quantity_min){
    this.cart[n].quantity -=1
    this.totalValue()
    this.saveCart(this.cart)
    }
  }
  addQuantity(n){
    this.cart[n].quantity +=1
    this.totalValue()
    this.saveCart(this.cart)
   
  }
  saveCart(cart){
    localStorage.setItem("cart", JSON.stringify(cart));
    
  };

  totalValue(){
    this.totalPrice=0;
    this.cart.forEach(element => {
      this.totalPrice += element.price * element.quantity
      
    });
  }


  try(){
    let obras = "Obras a entregar: "
    let n = this.cart.length
    for(let i=0; i<n;i++){
      this.cart[i].name
      
      obras += this.cart[i].name + ", "
    }
    return obras
  }

  paypal(){
    this.disabledBuy = false;
    let dollarUS = Intl.NumberFormat('en-US');
    let obras = this.try();
    
    render(
      {
        id: "#myPaypalButtons",
        currency: "USD",
        value: dollarUS.format(this.totalPrice),
        onApprove: (details) =>{
          this.sendEmail(obras);
        },
      }
    )
  }
  
  ngOnInit(): void {
      
  }

  sendEmail(value){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/f/xyylrple',
        { Obras: value },
        { 'headers': headers }).subscribe(
          response => {
            console.log(response);
            // swal("Pago de paypal", "Transacción exitosa", 'success');
          }
        );
  }

  close($event:boolean){
    this.numberCart = this.getCart().length;
    this.display=!$event

    this.newBooleanEvent.emit(!$event);
    this.disabledBuy = true;
  }

  getCart(){
    return localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  }

  clearCart(){
    localStorage.removeItem('cart');
    this.cart = this.getCart();
    this.totalValue()
  }

  deleteCartElement(n){
    let newcart = this.cart.filter(element => element.id != n)
    
    this.cart = newcart
    this.totalValue()
    localStorage.setItem("cart",JSON.stringify(newcart))
  }

  

}
