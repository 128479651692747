import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorksService } from 'src/app/works.service';

@Component({
  selector: 'app-obrainformacion',
  templateUrl: './obrainformacion.component.html',
  styleUrls: ['./obrainformacion.component.css']
})
export class ObrainformacionComponent{
  work:any = [];
  
  constructor(
    private ruta:ActivatedRoute,
    private _service:WorksService
  ) {
    this.ruta.params.subscribe(params=>{
      
      this.work = this._service.obtenerUno(params['id'])
    })
   }


}
