import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coral',
  templateUrl: './coral.component.html',
  styleUrls: ['./coral.component.css']
})
export class CoralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
