<header class="background-header"><app-navbaresp [number]="numberCart"></app-navbaresp></header>
<div class="background-img"></div>
<div class="contenedor">
    <div class="sidebar">
        <div class="contenido">
            <div class="search">
                <img src="./../../../../../assets/svg/search.svg" alt="">
                <input type="search" class="search-input" placeholder="Search..." name="filterPost" [(ngModel)]="filterPost">
            </div>
            
            <div class="selector">
                <h5 id="first-selector">Clasificación</h5>
                <div class="selector-item"><input type="radio" name="seleccion" id="" value="all" (click)="themeSelector('all')"> <div class="item"><p>Todas</p></div></div>
                <div class="selector-item"><input type="radio" name="seleccion" id="" value="Secular" (click)="themeSelector('Secular')"><div class="item"><p>Secular</p></div></div>
                
                <div class="selector-item"><input type="radio" name="seleccion" id="" value="Sacred" (click)="themeSelector('Sacred')"> <div class="item"><p>Sacro</p></div></div>
                <div class="selector-item"><input type="radio" name="seleccion" id="" value="chamber" (click)="themeSelector('chamber')"> <div class="item"><p>Cámara</p></div></div>
                <h5>Dificultad</h5>
                <div class="selector-item"><input type="radio" name="dificultad" id="" value="0" (click)="complexitySelector(0)"><div class="item"><p>Todas</p></div>  </div>
                <div class="selector-item"><input type="radio" name="dificultad" id="" value="1" (click)="complexitySelector(1)"><div class="item"><p>*</p></div>  </div>
                <div class="selector-item"><input type="radio" name="dificultad" id="" value="2" (click)="complexitySelector(2)"><div class="item"><p>**</p></div> </div>
                <div class="selector-item"><input type="radio" name="dificultad" id="" value="3" (click)="complexitySelector(3)"><div class="item"><p>***</p></div> </div>
                <div class="selector-item"><input type="radio" name="dificultad" id="" value="4" (click)="complexitySelector(4)"><div class="item"><p>****</p></div> </div>
                <div class="selector-item"><input type="radio" name="dificultad" id="" value="5" (click)="complexitySelector(5)"><div class="item"><p>*****</p></div> </div>
            </div>
        </div>
    </div>
        
    <div class="main">
        <div class="container">
            <div class="row"> <div class="center"><h1>Catálogo</h1></div> </div>
            <div class="row d-md-mode d-sm-none">
                <div class="selector-item"><input type="radio" name="seleccion" id="" value="all" (click)="themeSelector('all')"> <div class="item"><h6 class="text-white fs-5 px-2">All</h6></div></div>
                <div class="selector-item"><input type="radio" name="seleccion" id="" value="chamber" (click)="themeSelector('chamber')"> <div class="item"><h6 class="text-white fs-5 px-2">Chamber Music</h6></div></div>
             </div>
            <div class="row">

                <div class="search" id="search-mobile">
                    <img src="./../../../../../assets/svg/search.svg" alt="">
                    <input type="search" class="search-input" placeholder="Search..." name="filterPost" [(ngModel)]="filterPost">
                </div>
                <div class="col" *ngFor="let work of works | filterName: filterPost : filterTheme : complexity ">
                            
                            <div class="img-container" >
                                <img (click)="redirectToSingleWork(work.id)" *ngIf="work.image != ''; else noimage" [src]=work.image [alt]=work.name>
                                <ng-template #noimage>
                                    <div class="noimage text-center" (click)="redirectToSingleWork(work.id)">
                                        {{work.name}}
                                    </div>
                                </ng-template>
                                <div *ngIf="work.price != 0; else work0  ">
                                    <p >{{work.price |  currency: 'USD'}}</p>
                                    <a *ngIf="disableButton(work.id); else disabled" class="addCart" (click)="addToCart(work.id)"><div><img src="../../../../../assets/svg/cartplus.svg" alt=""></div><div>Agregar al carrito</div></a>
                                    <ng-template #disabled><a class="disableCart"><div><img src="../../../../../assets/svg/cartplus.svg" alt=""></div><div>En carrito</div></a></ng-template>
                                </div>
                                
                                <ng-template #work0>
                                    <a [href]=work.purchase target="_blank">Precio en Editorial</a>
                                </ng-template>
                            </div>
                    
                </div>
            </div>
        </div>
    </div>
      
            
        
        
        
    
    
</div>
