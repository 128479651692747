import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule, ParamMap } from '@angular/router';
import { BiographyComponent } from './views/public/english/biography/biography.component';
import { MediaComponent } from './views/public/english/media/media.component';
import { WorksComponent } from './views/public/english/works/works.component';
import { ContactComponent } from './views/public/english/contact/contact.component';
import { HomeComponent } from './views/public/english/home/home.component';
import { ChoralComponent } from './views/public/english/choral/choral.component';
import { WorksComponentsComponent } from './components/works-components/works-components.component';

import { EsComponent } from './views/public/español/es/es.component';
import { BiografiaComponent } from './views/public/español/biografia/biografia.component';
import { CatalogoComponent } from './views/public/español/catalogo/catalogo.component';
import { CoralComponent } from './views/public/español/coral/coral.component';
import { InstrumentalComponent } from './views/public/español/instrumental/instrumental.component';
import { ContactoComponent } from './views/public/español/contacto/contacto.component';
import { ObrainformacionComponent } from './components/obrainformacion/obrainformacion.component';
import { PedidosComponent } from './views/public/español/pedidos/pedidos.component';
import { ComissionsComponent } from './views/public/english/comissions/comissions.component';


const routes: Routes = [
  {
    path: 'biography',
    component: BiographyComponent,
  },
  {
    path: 'media',
    component: MediaComponent,
  },
  {
    path: 'works',
    component: WorksComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'choralmusic',
    component: ChoralComponent,
  },
  {
    path: 'workcomponent/:id',
    component: WorksComponentsComponent,
  },
  {
    path: 'comissions',
    component: ComissionsComponent
  },

  // Español
  {
    path: 'es',
    component: EsComponent,
  },
  {
    path: 'es/biografia',
    component: BiografiaComponent,
  },
  {
    path: 'es/catalogo',
    component: CatalogoComponent,
  },
  {
    path: 'es/coral',
    component: CoralComponent,
  },
  {
    path: 'es/instrumental',
    component: InstrumentalComponent,
  },
  {
    path: 'es/pedidos',
    component: PedidosComponent
  },
  {
    path: 'es/contacto',
    component: ContactoComponent
  },
  {
    path: 'es/obra-info/:id',
    component: ObrainformacionComponent
  },

  {path: '**', component:ContactComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
