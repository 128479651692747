<header class="background-header"> <app-navbaresp></app-navbaresp></header>
<div class="bg-biography"></div>
    <div class="flex-container">
        <div class="col-md-3 hero is-fullheight"></div>
        <div id= "text-bio" class="col-md-6 hero is-fullheight is-bold ">
            
            
           Nací en Córdoba, Argentina, en 1983, pero mi infancia transcurrió en Catamarca. Allí mi abuelo Alberto me enseñó los primeros acordes en la guitarra y en mi adolescencia formé algunas bandas de rock donde escribí mis primeras canciones. Al establecerme en Córdoba estudié la Licenciatura en Composición Musical en la Facultad de Artes de la UNC donde el compositor y profesor Eleazar Garzón fué mi maestro y mentor.

<br>Al iniciar mi carrera universitaria me vi atraído hacia la actividad coral, hecho que influyó en gran manera en mis elecciones compositivas, no sólo motivándome a escribir para voces sino atrayéndome también a la dirección coral y más tarde, a la dirección orquestal.

<br>En el transcurso de los años he tenido la posibilidad de dirigir numerosos grupos corales e instrumentales, mientras mi música ha ido poco a poco encontrando diversos espacios y otras voces alrededor del mundo donde resonar. Hoy mi producción compositiva está orientada a la música coral y a la música de cámara, dándome el espacio para continuar cultivando profundamente mi perfil sinfónico mientras que a la vez me vinculo más y más con la música para cine.

<br>Actualmente resido en Copenhague - Dinamarca, donde realizo mi posgrado en composición musical en la Real Academia Danesa de Música (RDAM - The Royal Danish Academy of Musik).




        </div>
        <div class="col-md-3 hero is-fullheight"></div>
      </div>


