<header class="background-header">
  <app-navbar></app-navbar>
</header>
<div class="background-img"></div>
  <div class="flex-container">
    <div class="col-md-3 hero is-fullheight"></div>
    <div id="text-bio" class="col-md-6 hero is-fullheight is-bold ">
      


      I was born in Córdoba, Argentina, but I grew up in Catamarca. It was there that my grandfather Alberto taught me my first chords on the guitar, and where, as a teenager, I started the rock bands for which I wrote my first songs. Later on, I moved to Córdoba, where I pursued a degree in Music Composition at the School of Arts of the National University of Córdoba. The composer Eleazar Garzón was my mentor and teacher there.
      <br>
      Early in my studies, I fell in love with choral music, which had a profound creative influence on me, leading to my passion for choral conducting and, later on, for orchestral conducting.
      <br>
      Over the years, I have been blessed to conduct several choral and instrumental ensembles. Meanwhile, my music has gradually found different spaces and voices around the world in which to resonate. Today, my compositional work is mainly focused on choral music and chamber music. However, my curiosity and inquisitiveness are constantly driving me to nurture my symphonic profile, and get more and more engaged with music for films.
      <br>
      I currently live in Copenhagen, Denmark, where I am undertaking postgraduate studies in music composition at the Royal Danish Academy of Music (RDAM).



    </div>
    <div class="col-md-3 hero is-fullheight"></div>
  </div>
