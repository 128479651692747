<header><app-navbar></app-navbar></header>
<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="6000">
        
        <div class="carousel-caption ">
          <h5>" I have a deep spiritual need to compose."</h5>
          <p>Conceiving ourselves in sounds</p>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="7000">
        
        <div class="carousel-caption">
          <h5>" Finding ways to connect with the interpreter through my ideas is a journey I enjoy immensely."</h5>
          <p>Conceiving ourselves in sounds</p>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="6000">
        
        <div class="carousel-caption ">
          <h5>" This Moebius tape is filled with the plenitude I experience whenever I can touch the hearts of those who listen to my music."</h5>
          <p>Conceiving ourselves in sounds</p>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="7000">
        
        <div class="carousel-caption ">
          <h5>" It is in that precise moment when everything falls into place, since, in some way, we are all being heard, understood, and embraced."</h5>
          <p>Conceiving ourselves in sounds</p>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
    <a class="transparent"  routerLink="/es" routerLinkActive="active">Español </a>  
    <a class="transparent" href="/"> English</a>
  </div>
  
    
  
